import React, { FC, ReactNode } from 'react'

import { styled } from '@linaria/react'

import { WithChildren } from 'common/types'

import { ButtonLoader } from './ButtonLoader'
import { ICON_GAP, SIZES_MAP_PX } from './constants.common'
import { Size } from './types.common'

export const ButtonInner: FC<
  { size: Size; icon?: ReactNode; loading?: boolean } & WithChildren
> = ({ size, icon, loading, children }) => {
  return (
    <Inner style={{ height: SIZES_MAP_PX[size] }}>
      {/* Возможно надо переделать расстояния между элементами на flex gap */}
      <ButtonLoader size={size} show={loading} />
      {!loading && icon && <Icon>{icon}</Icon>}
      <Inner>{children}</Inner>
    </Inner>
  )
}

const Inner = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
`

const Icon = styled.span`
  display: flex;

  html[dir='ltr'] & {
    margin-right: ${ICON_GAP}px;
  }

  html[dir='rtl'] & {
    margin-left: ${ICON_GAP}px;
  }
`
